import React from 'react';
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import {useRecoilValue} from "recoil";

export const Logo = ({width = "100%"}) => {
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const {
    logo = "/img/logo.png"
  } = selectedUnidade || {};

  return (
    <React.Fragment>
      <img
        src={logo}
        alt=""
        style={{
          width: width,
          height: "auto",
          objectFit: "contain"
        }}
      />
    </React.Fragment>
  );
};
