import {gql} from "@apollo/client";

export interface  MutateRefreshToken {
    refreshToken: any
}

const MUTATE_REFRESH_TOKEN = gql`
    mutation refreshToken($refreshToken: String) {
    refreshToken(refreshToken: $refreshToken) {
      succeeded
      errors
      data {
        authToken : accessToken
        expiresIn
        refreshToken
        userToken {
          id
          login
          name
          roleId
          roleName
          permissions {
            permissionName
          }
        }
      }
    }
  }
`;

export default MUTATE_REFRESH_TOKEN;
