import React, {useEffect, useState} from "react";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {BrowserAuthError, CacheLookupPolicy, InteractionStatus, InteractionType} from "@azure/msal-browser";
import {fetchDataGET} from "../fetch";
import {ApolloProvider} from "@apollo/client";
import context from "../context.json";
import {fetchImageGet} from "../utils/fetchImageGet";
import * as _authService from '../service/auth-service';
import {useRecoilState} from "recoil";
import {permissionsState} from "../recoil/atoms/permissionsState";
import create, {STORAGE_TOKEN_KEY, STORAGE_REFRESH_TOKEN_KEY} from "../libs/apollo";
import LoadingAnimation from "../components/LoadingAnimation/LoadigAnimation";

const AuthContext = React.createContext({
  listUnidades: [],
  listPermissions: [],
  setListPermissions: () => {},
  setListUnidades: () => {},
  logoUnidade: "",
  setLogoUnidade: () => {},
  setUserImage: () => { },
  appContext: {},
  userImage: "",
});

const AuthProvider = ({ children }) => {
  const scopes = ["3d90cd04-7efd-4151-a764-201e5959e56e/.default"];
  const scopesGraph = ["user.read", "group.read.all"];
  let { result, error, login } = useMsalAuthentication(
    InteractionType.Popup,
    {
      scopes: scopesGraph,
      state: `${window.location.pathname}${window.location.search}`
    }
  );
  const [graphData, setGraphData] = useState(null);
  const [listUnidades, setListUnidades] = useState([]);
  const [listPermissions, setListPermissions] = useRecoilState(permissionsState);
  const [logoUnidade, setLogoUnidade] = useState(null);
  const [client, setClient] = useState(null);
  const [appConfig] = useState(context);
  const { instance, inProgress, accounts } = useMsal();
  const [userImage, setUserImage] = useState(null);
  console.log(instance, accounts)

  useEffect( () => {
    const doAuth = async () => {
      if (!!graphData) {
        return
      }
      if (!!error) {
        if (error instanceof BrowserAuthError) {
          login(InteractionType.Redirect, {
            scopes: scopesGraph,
            state: `${window.location.pathname}${window.location.search}`
          })
        }
        console.error(error);
      }

      if (inProgress === InteractionStatus.None) {
        if (!result) {
          result = await instance.acquireTokenSilent({
            scopes: scopesGraph,
            account: accounts[0],
            forceRefresh: true,
            cacheLookupPolicy: CacheLookupPolicy.Default
          });
        }

        sessionStorage.setItem('@SECRETARIA/nomeUsuarioLogado', result.account.name);
        sessionStorage.setItem('@SECRETARIA/emailUsuarioLogado', result.account.username);

        const fetchGraphData = async () => {
          await fetchDataGET("https://graph.microsoft.com/v1.0/me", result.accessToken)
            .then(data => setGraphData(data))
            .catch(error => console.log(error));
          const object = await fetchImageGet("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", result.accessToken);
          let blob = new Blob([object], { type: "image/jpeg" });
          let newUrl = URL.createObjectURL(blob);
          setUserImage(newUrl);
        }

        const fetchMemberOf = async () => {
          await fetchDataGET("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$orderby=displayName&$select=id,displayName,description", result.accessToken)
            .then(data => {
              let gruposSecretaria = data.value?.filter(grupos => grupos.displayName.toLowerCase().indexOf("secretaria") > -1);
              let codigoUnidadeList = gruposSecretaria?.map(({description}) =>  description)
              sessionStorage.setItem('@SECRETARIA/unidadesPermitidas', JSON.stringify(codigoUnidadeList))
              setListUnidades(codigoUnidadeList);
            }).catch(error => console.log(error));
        }

        const signInGraph = async () => {
          const accessTokenRequest = {
            scopes,
            account: accounts[0],
            forceRefresh: true,
            cacheLookupPolicy: CacheLookupPolicy.Default
          };

          const resp = await instance.acquireTokenSilent(accessTokenRequest)
          localStorage.setItem(STORAGE_TOKEN_KEY, resp.accessToken || '');
          const {permissions, authToken, refreshToken} = await _authService.signIn(resp.accessToken);
          setListPermissions(permissions);
          localStorage.setItem(STORAGE_TOKEN_KEY, authToken || '');

          if (refreshToken)
            localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken)
        }

        fetchGraphData();
        fetchMemberOf();

        if (!client) {
          const apolloClient = create();
          window.apolloClient = apolloClient;
          window.msalInstance = instance;

          await signInGraph();

          setClient(apolloClient);
        }

        if (result?.state && result?.state !== `${window.location.pathname}${window.location.search}`) {
          window.location.href = `${window.location.pathname}${window.location.search}`;
        }
      }
    }

    doAuth();
  }, [error, result, graphData, login, client, instance, accounts, inProgress]);

  if (!client)
    return <LoadingAnimation/>

  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={{
        userImage,
        setUserImage,
        listUnidades,
        setListUnidades,
        listPermissions,
        setListPermissions,
        logoUnidade,
        setLogoUnidade,
        client,
        appContext:
        appConfig
      }}>
        {children}
      </AuthContext.Provider>
    </ApolloProvider>
  )
}
export { AuthContext, AuthProvider };
