import { useEffect, useState } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import moment from 'moment'
import * as _responsavelService from "../../service/responsavel-service";
import * as _matriculaService from "../../service/matricula-service";
import * as Message from "../../components/shared/Message";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';
import Box from "@mui/material/Box";
import {Logo} from "../../components/logo";
import './styles.css'
import { useRecoilValue } from 'recoil';
import { selectedUnidadeState } from 'src/recoil/atoms/selectedUnidadeState';


const DeclaracaoDeBolsa = (props: any) => {
    const { Matricula, Unidade } = props;
    const [serieMatricula,setSerieMatricula] = useState(Matricula.servico.split('-')[2]);
    const [responsavel, setResponsavel] = useState('');
    const [errorText, setErrorText] = useState('');
    const [bolsa, setBolsa] = useState('');
    const [isloading, setIsLoading] = useState(true);
    const [erroRequisicao, setErroRequisicao] = useState(false);
    const selectedUnidade = useRecoilValue(selectedUnidadeState);
    const {
      logo
    } = selectedUnidade || {};
    const data = moment().format('DD/MM/YYYY')

    const printPDF = () => {
        window.print();
    };
    useEffect(() => {
        setErroRequisicao(false);
        setIsLoading(true);
        Promise.all([GetResponsavelByMatricula(), buscaMatriculaGeral(), GetMatricula()])
    }, [Matricula])

    const buscaMatriculaGeral = async () => {
        var matriculaGeral = await _matriculaService.GetMatriculaGeral(Matricula.idMatricula);
        if (matriculaGeral && matriculaGeral.bolsaVigente && matriculaGeral.bolsaVigente.percentual) {
            setBolsa(matriculaGeral.bolsaVigente.percentual.toFixed(2));
        } else {
            Message.ExibeMensagem("A bolsa vigente da matrícula não foi encontrada!", "2", false);
            setErroRequisicao(true);
            setErrorText('A bolsa vigente da matrícula não foi encontrada!');
        }
        setIsLoading(false);
    }

    const GetResponsavelByMatricula = async () => {
        var responsavel = await _responsavelService.GetResponsavelByMatricula(Matricula.idMatricula);
        if (responsavel && responsavel.nome) {
            setResponsavel(responsavel.nome);
        } else {
            Message.ExibeMensagem("O responsável da matrícula não foi encontrado!", "2", false);
            setErroRequisicao(true);
            setErrorText('O responsável da matrícula não foi encontrado!');
        }
    }

    const GetMatricula = async () => {
        var matricula = await _matriculaService.GetMatricula(props.Matricula.idMatricula);
        setSerieMatricula(matricula.nomeLandingPage ?? Matricula.servico.split('-')[2]);
    }
    
    if (erroRequisicao) {
        return <ErrorMessage message={errorText} />
    }
    if (isloading) {
        return (<LoadingAnimation> </LoadingAnimation>)
    }
    return (
        <>
            <div className='print-content'>
                <button className="print-button" onClick={printPDF}>Imprimir</button>
            </div>
            <div className="declaracao-component">
                <div className="declaracao-container">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '0 auto',
                            height: !logo ? 120 : 50,
                            p: '4px',
                            width: "100%",
                        }}
                    >
                        <Logo 
                            width={!logo ? '200px' : '150px'}
                        />
                    </Box>
                    <div className='subtitulo texto-variavel-paragrafo'>Matrícula: <div> {Matricula.codigoMatricula} - {Matricula.anoLetivo_Name}</div></div>
                    <h4>DECLARAÇÃO</h4>
                    <div className='texto-variavel-paragrafo'>Eu,&nbsp;<div>{responsavel}</div>&nbsp; responsável pelo aluno(a)&nbsp;<div>{Matricula.estudante_Name}</div>, aluno(a) regularmente matrículado(a) na série/ano:&nbsp;<div>{serieMatricula}&nbsp;/&nbsp;{Matricula.anoLetivo_Name}</div></div>
                    <div className='texto-variavel-paragrafo'>DECLARO, para os fins que se fizerem necessários, que recebi do&nbsp;<div>{Unidade.nome}</div>, uma bolsa de estudo, nos termos dos regulamentos internos da instituição, de&nbsp;<div>{bolsa}%</div>. </div>
                    <p>Outrossim, declaro ainda que o referido desconto, dentre outros descontos eventualmente concedidos, pode ser cancelado caso ocorra uma das seguintes hipóteses ao Aluno que, a qualquer tempo, durante o ano vigente tenha:</p>
                    <p>(a). Mantida frequência mínima abaixo de 85% (oitenta e cinco por cento) de presença em qualquer disciplina;</p>
                    <p>(b). Apresentado baixo desempenho escolar;</p>
                    <p>(c). Ficado inadimplente por mais de 90 (noventa) dias consecutivos ou não, ou 03 (três) parcelas da anuidade;</p>
                    <p>(d). Apresentado em seu currículo escolar qualquer anotação de indisciplina caracterizada como GRAVE, conforme disposição contida no Regimento Escolar/Interno e/ou no "Manual de Convivência".</p>
                    <p>O desconto concedido é válido exclusivamente para o presente ano letivo, podendo ser renovado de acordo com as condições mencionadas acima.</p>
                    <p>O desconto concedido poderá ser alterado mediante a emissão de uma nova declaração, presumindo-se sua revogação na data anterior à da nova declaração.</p>
                    <p>O desconto concedido aplica-se somente sobre a Prestação de Serviços Educacionais regulares, não sobre serviços adicionais (Upselling), Material Escolar e Livros.</p>
                    <div className='texto-variavel-paragrafo'>Bolsa:&nbsp;<div>{bolsa}%</div></div>
                    <p>Por ser a expressão da verdade, assino a presente.</p>
                    <div className="declaracao-footer">
                        <div>
                            <div className='texto-variavel-paragrafo'>LOCAL:&nbsp;<div>{Unidade.cidade}/{Unidade.estado}</div></div>
                            <br />
                            <hr />
                            <p className='texto-com-negrito'>Responsável pela INSTITUIÇÃO</p>
                        </div>
                        <div>
                            <div className='texto-variavel-paragrafo'>DATA:&nbsp;<div>{data}</div></div>
                            <br />
                            <hr />
                            <div className="legenda-footer">
                                <div className='texto-variavel-paragrafo'><div>{responsavel}</div></div>
                                <p className='texto-com-negrito'>Responsável Legal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withAppContext(withUnidadeMatricula(DeclaracaoDeBolsa, 'Declaracao De Bolsa'));