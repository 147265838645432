import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import Card from "@mui/material/Card";
import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import useCsat from "../hooks/use-csat";
import Csat from "../components/Csat/Csat";
import {permissionsState} from "../recoil/atoms/permissionsState";
import {selectedColaboradorState} from "../recoil/atoms/selectedColaboradorState";
import {colaboradoresState} from "../recoil/atoms/colaboradoresState";
import Stack from "@mui/material/Stack";
import {Alert} from "@mui/material";
import {showSelectColaboradorState} from "../recoil/atoms/showSelectColaboradorState";
import { showSelectMatriculasState } from "src/recoil/atoms/showSelectMatriculasState";
import {usePageView} from "../hooks/use-page-view";
import { loadingColaboradoresState } from "src/recoil/atoms/loadingColaboradoresState";

const defaultOptions = {
  needMat: true,
  csatTitle: "O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?"
}

export default function withUnidadeColaborador(Component, title = '', csatName = '', csatTitle = "O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?", options = defaultOptions) {
  return function _hoc(props) {
    const {
      needMat = true
    } = options;
    const colaborador = useRecoilValue(selectedColaboradorState);
    const colaboradores = useRecoilValue(colaboradoresState);
    const loadingColaboradores = useRecoilValue(loadingColaboradoresState);
    const unidade = useRecoilValue(selectedUnidadeState);
    const permissions = useRecoilValue(permissionsState);
    const setShowColaboradores = useSetRecoilState(showSelectColaboradorState);
    const setShowMatriculas = useSetRecoilState(showSelectMatriculasState);
    const pageTitle = (title ? `${title} | ` : '') + `Secretaria Web`
    const {
        addAnsweredCsat,
        isCsatVisible,
        handleShowCsat
    } = useCsat(csatName);

    usePageView();

    useEffect(() => {
      setShowColaboradores(true);
      setShowMatriculas(false);
    }, [])

    return (
        <>
            <Csat
                show={isCsatVisible}
                scoreTitle={csatTitle}
                onSubmit={() => addAnsweredCsat(csatName)}
                onClosed={() => addAnsweredCsat(csatName)}
            />

            <Card
                sx={{
                    m: 2,
                    position: 'relative',
                    minHeight: '600px',
                    padding: '20px',
                }}
            >
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                {(needMat && !colaborador) || !unidade ? (
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: '100%'
                      }}
                    >
                      <Alert severity="warning">Selecione a unidade e o Colaborador</Alert>
                    </Stack>
                ) : (
                    <Component
                        {...props}
                        Colaborador={colaborador}
                        colaboradores={colaboradores}
                        loadingColaboradores={loadingColaboradores}
                        Unidade={unidade}
                        permissions={permissions}
                        handleShowCsat={handleShowCsat}
                    />
                )}
            </Card>
        </>
    );
  }
}
