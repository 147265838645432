import {Unidade} from "../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import {useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import { selectedColaboradorState } from "src/recoil/atoms/selectedColaboradorState";

export default function useSelectUnidade() {
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const setSelectedColaborador = useSetRecoilState(selectedColaboradorState);
  const setUnidade = useSetRecoilState(selectedUnidadeState);
  
  return {
    selecioneUnidade: (obj: Unidade) => {
      if (obj) {
        sessionStorage.setItem('@SECRETARIA/unidade', JSON.stringify(obj));
        sessionStorage.setItem('@SECRETARIA/logoSistema', obj?.logo);
        sessionStorage.setItem('@SECRETARIA/idUnidade', obj?.id);
        sessionStorage.setItem('@SECRETARIA/entidadeLegal', obj?.codigoERP);
        sessionStorage.setItem('@SECRETARIA/dataAreaId', obj?.dataAreaId);
        setSelectedMatricula(null);
        setSelectedColaborador(null);
        setUnidade(obj);
        window.location.reload();
      }
    }
  }
}