import React, {FC} from 'react';
import './styles.css';

const SuccessCheckmark: FC<{
	className?: string
}> = ({className}) => {
	return (
		<div className={`successCheckmark ${className}`}>
			<div className='checkIcon'>
				<span className='iconLine lineTip'></span>
				<span className='iconLine lineLong'></span>
				<div className='iconCircle'></div>
				<div className='iconFix'></div>
			</div>
		</div>
	);
}

export default SuccessCheckmark;
